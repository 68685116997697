mat-select-trigger {
  mat-icon {
    position: absolute;
    margin-top: -6px;
  }

  app-content-item-icon + span {
    padding-left: 24px + 10px;
  }
}

.mat-option {
  span + app-content-item-icon {
    .mat-icon {
      margin-left: 16px;
    }
  }
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
  padding: 0 5px 0 16px !important;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    height: 80px;
  }
}

.search-list-item,
.loading-list-item {
  .mat-list-item-content {
    justify-content: center;
  }

  &:hover {
    background-color: none !important;
    cursor: default;
  }
}

.search {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin-right: 1rem;
  }

  .mat-form-field {
    width: 100%;
  }
}

.mat-drawer-container {
  background-color: #fff !important;
}

.language-icon {
  width: 14px !important;
  height: 14px !important;
  font-size: 14px;
}
