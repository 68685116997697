.static-page-content {
  a {
    color: $color-darkgrey !important;
    text-decoration: underline !important;

    &:hover, &:focus {
      color: $color-primary !important;
    }
  }

  ul, li {
    padding-inline-start: 0;
    list-style: none;
  }

  ul li {
    padding: 0 .5rem .5rem;
  }

  .map-icon-list {
    li {
      display: flex;
      align-items: center;
    }
  }
}
