.mobile {
  .static-page-content, .content {
    max-width: 100% !important;
  }

  .content .description {
    width: 80vw !important;
  }

  .sidenav-container .footer {
    width: 100% !important;
  }
}