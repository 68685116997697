@import "variables";
@import "mixins";


/*
Trade Gothic Next Condensed Bold, licensed from fonts.com
http://www.fonts.com/font/linotype/trade-gothic-next/condensed-bold
*/

@font-face{
  font-family:'TradeGothicNextCondBold';
  src:url('/assets/fonts/TradeGothicNextCondBold/62cdf702-7113-4955-9b29-d803174650c7.eot?#iefix');
  src:url('/assets/fonts/TradeGothicNextCondBold/62cdf702-7113-4955-9b29-d803174650c7.eot?#iefix') format('eot'),
  url('/assets/fonts/TradeGothicNextCondBold/a43fe47f-004d-4b6d-9a72-23de9e08f66e.woff') format('woff'),
  url('/assets/fonts/TradeGothicNextCondBold/de22c287-e0dc-4287-8a0e-3f68a8788330.ttf') format('truetype'),
  url('/assets/fonts/TradeGothicNextCondBold/d7c320de-0c26-418a-b1d2-ef600169db34.svg#d7c320de-0c26-418a-b1d2-ef600169db34') format('svg');
}


// region General
body {
  line-height: $line-height-regular;
  font-size: $font-size-regular;
}

p {
  @include no-margin-first-last;
}
// endregion

// region Headings
h1, h2, h3, h4, h5, h6 {
  @include font-title;
  @include no-margin-first-last;
  line-height: $line-height-title;

  & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
    margin-top: $font-margin-top-content;
  }
}

.mat-h2, .mat-title, .mat-typography {
  & h1 {
    @include title-typography($font-size-h1, $font-margin-top-h1, $font-margin-bottom-h1);
  }

  & h2 {
    @include title-typography($font-size-h2, $font-margin-top-h2-h3-h4, $font-margin-bottom-h2-h3-h4);
  }

  & h3 {
    @include title-typography($font-size-h3, $font-margin-top-h2-h3-h4, $font-margin-bottom-h2-h3-h4);
  }

  & h4 {
    @include title-typography($font-size-h4, $font-margin-top-h2-h3-h4, $font-margin-bottom-h2-h3-h4);
  }
}
// endregion
