button.new {
  position: fixed;
  right: 24px;
  bottom: 24px;
}

// TODO: Use page layout for spacing instead?
.paginator {
  // Space for the "new" button
  margin-bottom: 48px;
}

.share-links {
  .share-facebook,
  .share-twitter {
    display: inline-block;
    width: 32px;
    height: 32px;

    background-repeat: no-repeat;
    background-position-y: 8px;

    cursor: pointer;

    opacity: 0.6;

    border: 2px solid $color-grey;
    border-radius: 16px;

    &:hover,
    &:active {
      opacity: 1;
    }
  }

  .share-facebook {
    background-position-x: 8px;
    background-image: url("/assets/images/icons/share-facebook-hover.png");
    margin-right: 3px;
  }

  .share-twitter {
    background-position-x: center;
    background-image: url("/assets/images/icons/share-twitter-hover.png");
  }
}

// Map overrides
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

.tooltip-content {
  img {
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 150px;
  }

  .title {
    text-align: center;
    padding: 0.5rem 1rem 1rem;
    margin: 0;
  }

  .label {
    text-transform: uppercase;
    padding: 0 1rem 1rem;
    text-align: center;
  }
}
